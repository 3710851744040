
jQuery.noConflict();
jQuery(document).ready(function($) {


    $(window).on('load', function () {

        // owlCarousel
        //$('.owl-carousel').owlCarousel();
        $('.slide-fotos').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 4
                }
            }

        });



        $('.slide-principal').owlCarousel({
            pagination: true,
            nav: true,
            loop: true,
            dots: false,
            margin: 0,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }

        });






        $('.slide-actividades, .slide-servicios, .slide-noticias').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1200: {
                    items: 3
                },
            }

        });


        $('.slide-products').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1200: {
                    items: 4
                },
            }

        });



        $(".owl-prev").html('<span class="fa fa-angle-left"></span>');
        $(".owl-next").html('<span class="fa fa-angle-right"></span>');

    });



    // jquery code
    $(document).ready(function () {


        // Bootstrap Auto-Hiding Navbar 
        $(".header").autoHidingNavbar();


        // Highlight the top nav as scrolling occurs
        $(window).scroll(function() {
            if ($(document).scrollTop() > 250) {
                $('.header').addClass('shrink');
            } else {
                $('.header').removeClass('shrink');
            }
        });


        // Add active dropdown
        /*
        $('.dropdown').hover(function(){ 
            $('.dropdown-toggle', this).trigger('click'); 
        });
        */




        // Animate elements when scrolled into view with native JavaScript
        var animateHTML = function() {
            var elems;
            var windowHeight;

            function init() {
                elems = document.querySelectorAll('.hidden');
                windowHeight = window.innerHeight;
                addEventHandlers();
                checkPosition();
            }

            function addEventHandlers() {
                window.addEventListener('scroll', checkPosition);
                window.addEventListener('resize', init);
            }

            function checkPosition() {
                for (var i = 0; i < elems.length; i++) {
                    var positionFromTop = elems[i].getBoundingClientRect().top;
                    if (positionFromTop - windowHeight <= 0) {
                        elems[i].className = elems[i].className.replace(
                            'hidden',
                            'fade-in-element'
                        );
                    }
                }
            }
            return {
                init: init
            };
        };
        animateHTML().init();
        





        // magnificPopup for .image-link
        $('.image-link').magnificPopup({ type: 'image' });


        $('.popup-gallery').magnificPopup({
            delegate: '.owl-item:not(.cloned) a',
            type: 'image',
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
                beforeOpen: function () {
                    // just a hack that adds mfp-anim class to markup 
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title') + '<small></small>';
                }
            }
        });


        // Popup
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,

            fixedContentPos: false
        });


        // Cambiar clases botones
  

        $('.woocommerce-MyAccount-content .woocommerce-Input').addClass('form-control');
        

        

        $('.single_add_to_cart_button').addClass('btn btn-primary');
        $('.single_add_to_cart_button').removeClass('button');
        

        $('.wc-forward').addClass('btn btn-primary');
        $('.wc-forward').removeClass('button');


        $('a.checkout-button ').addClass('btn');
        $('a.checkout-button ').removeClass('button');


        $('input[type="submit"]').addClass('btn');
        $('input[type="submit"]').removeClass('button');


        // the selector for gallery item
        $('.woocommerce-product-gallery__wrapper').each(function() { // the containers for all your galleries
            $(this).magnificPopup({
                delegate: 'a', // the selector for gallery item
                type: 'image',
                gallery: {
                enabled:true
                }
            });
        });
        

        // Contact
        $('.wpcf7-form .wpcf7-text').addClass('form-control');
        $('.wpcf7-textarea').addClass('form-control');
        $('.wpcf7-date').addClass('form-control');
        $('.wpcf7-submit').addClass('btn btn-primary');
        //$('.input-xlarge').addClass('form-control');



    
        



    });


});
